import React from "react";

import { useContext } from "react";
import { FormContext, FieldDataContext, InputContext } from "../../../contexts";

import {
  BooleanInput,
  CheckInput,
  DatePickerInput,
  SelectInput,
  TextInput,
} from "../input_types";

const ProspectField = ({ displayItem }) => {
  // Get required values from our context
  const {
    subforms,
    readonly,
    lookups,
    siteMetaLookups,
    studyListOptions,
    auditPage,
  } = useContext(FormContext);
  const {
    fields,
    formValues,
    updateFormValue,
    buildInputName,
    buildUniqueIdentifer,
  } = useContext(FieldDataContext);

  // Get our inputContext
  const { inputContext, subformIdentifier } = useContext(InputContext);

  const tableOrSubformField =
    inputContext == "table" || inputContext == "subform";

  // Find the current field
  const field = fields.find((field) => {
    return field.identifier == displayItem.fieldIdentifier;
  });

  // If we didn't find a matching field, exit with warning
  if (!field) {
    console.warn(
      `Unable to find a matching field for displayitem :"${displayItem.fieldIdentifier}"`
    );
    return null;
  }

  // Do not show calculated fields outside of the view page
  if (field.calculated && !readonly) {
    return null;
  }

  // Do not show dynamically calculated fields on the audit page
  if (field.dynamic && auditPage) {
    return null;
  }

  // Get a reference to our field data
  const fieldData = formValues[field.identifier];

  const inputName = buildInputName(field.identifier);
  const fieldID = buildUniqueIdentifer(field.identifier);

  if (!fieldData.dependency_met) {
    // If the dependency is not met, but we are on the view page and the field is entered we still show the field
    if (!(readonly && fieldData.entered)) {
      return null;
    }
  }

  /**
   * The basic on change handler used by most fields
   * @param {*} e the input event
   */
  const onChangeHandler = (e) => {
    updateFormValue(field.identifier, e.target.value);
  };

  /**
   * The clear selection handler
   */
  const clearSelectionHandler = () => {
    updateFormValue(field.identifier, "");
  };

  // Get the errors list for the current field
  const errors = fieldData.errors;

  // Props shared by all input types (override as necessary)
  let inputProps = {
    fieldData: fieldData,
    onChangeHandler,
    clearSelectionHandler,
    errors,
    field,
    displayItem,
    readonly,
    inputName,
    fieldID,
  };

  let ChosenComponent = null;

  switch (displayItem.widgetType) {
    case "textArea":
    case "textSmall":
    case "text": {
      if (!readonly) {
        if (field.type == "time" && field.type != "duration") {
          inputProps["fieldPostText"] = "(24 hour clock)";
        } else if (field.type == "decimal") {
          inputProps["fieldPostText"] = `(${field.precision} dp)`;
        }
      }
      let placeholder = buildPlaceHolder(field);
      if (placeholder) {
        inputProps["placeholder"] = placeholder;
      } else if (fieldData["placeholder"]) {
        inputProps["placeholder"] = fieldData["placeholder"];
      }
      if (field.unit) {
        inputProps["unit"] = field.unit;
      }
      if (field.stringFormat) {
        inputProps["stringFormat"] = field.stringFormat;
      }
      if (displayItem.preventSubmitOnEnter) {
        inputProps["preventSubmitOnEnter"] = true;
      }
      ChosenComponent = TextInput;
      break;
    }
    case "checkbox": {
      // Override the on change handler for this field
      inputProps.onChangeHandler = () => {
        updateFormValue(field.identifier, !fieldData.value);
      };
      ChosenComponent = BooleanInput;
      break;
    }
    case "enumRadio":
    case "radioHorizontal":
    case "radioVertical": {
      inputProps["options"] = findOptions(
        field,
        lookups,
        siteMetaLookups,
        studyListOptions
      );
      ChosenComponent = CheckInput;
      break;
    }
    case "SelectBox":
    case "selectBox": {
      if (readonly) {
        ChosenComponent = TextInput;
        break;
      } else {
        inputProps["options"] = findOptions(
          field,
          lookups,
          siteMetaLookups,
          studyListOptions
        );
        ChosenComponent = SelectInput;
        break;
      }
    }
    case "date": {
      // Override our on change handler
      inputProps.onChangeHandler = (date) => {
        updateFormValue(field.identifier, transformDateFormat(date) || null);
      };
      // If the form is readonly, dont render this as a date picker
      if (readonly) {
        ChosenComponent = TextInput;
      } else {
        ChosenComponent = DatePickerInput;
      }
      break;
    }
    case "checkboxVertical":
    case "checkboxHorizontal":
    case "checkboxes": {
      inputProps["options"] = findOptions(
        field,
        lookups,
        siteMetaLookups,
        studyListOptions
      );
      if (!readonly) {
        inputProps["labelSubtext"] = "Tick all that apply";
      }
      // Override our on change handler
      inputProps.onChangeHandler = (e) => {
        // The slice here means we have a copy of the array, as opposed to a reference.
        // Was causing a bug where the initialValue for the field was being overridden
        let existingValue = fieldData.value.slice(0);
        let addedValue = e.target.value;

        if (e.target.checked) {
          if (!existingValue.includes(addedValue)) {
            existingValue.push(addedValue);
          }
        } else {
          const index = existingValue.indexOf(addedValue);
          if (index > -1) {
            existingValue.splice(index, 1);
          }
        }

        existingValue.sort();

        updateFormValue(field.identifier, existingValue);
      };
      ChosenComponent = CheckInput;
      break;
    }
    default: {
      console.warn(
        `Unable to render widget of type: "${displayItem.widgetType}"`
      );
      return null;
    }
  }

  // Return our component
  return (
    <>
      <ChosenComponent {...inputProps} />
      {field.hint && (
        <div
          className={`field-hint field-hint-${field.identifier} ${
            tableOrSubformField ? "hint-table-subform-context" : ""
          } mt-1 mb-3 text-muted fst-italic`}
        >
          {field.hint}
        </div>
      )}
    </>
  );
};

/**
 * Finds the relevant enum/flag options for the given field
 * @param {*} field the current field
 * @param {*} lookups a list of regular lookups from the json structure
 * @param {*} siteMetaLookups a list of site meta lookups specific to this form/site
 * @param {*} studyListOptions a list of study list options grouped by list identifier
 * @returns
 */
const findOptions = (field, lookups, siteMetaLookups, studyListOptions) => {
  if (field.type == "siteLookup") {
    const lookupIdentifier = field.siteMetaLookup.replace("site.", "");
    return siteMetaLookups.find((lookup) => {
      return lookup.identifier == lookupIdentifier;
    });
  } else if (field.type == "studyLookup") {
    return studyListOptions[field.studyListIdentifier];
  } else {
    return lookups.find((lookup) => {
      return lookup.structure.identifier == field.lookup;
    }).structure;
  }
};

/**
 * Returns the appropriate placeholder text (if any) for the given field
 * @param {*} field the current field
 * @returns
 */
const buildPlaceHolder = (field) => {
  if (field.type == "date") {
    return "dd/mm/yyyy";
  } else if (field.type == "partialDate") {
    let placeholderParts = [];
    if (field.partialDateFormat.day == "optional") {
      placeholderParts.push("{dd}");
    } else if (field.partialDateFormat.day == "required") {
      placeholderParts.push("dd");
    }

    if (field.partialDateFormat.month == "optional") {
      placeholderParts.push("{mm}");
    } else if (field.partialDateFormat.month == "required") {
      placeholderParts.push("mm");
    }
    placeholderParts.push("yyyy");

    return placeholderParts.join("/");
  } else if (field.type == "time") {
    return field.timeFormat;
  } else if (field.type == "duration") {
    return field.durationFormat;
  }

  return null;
};

/**
 * Transforms a date from the server format to that required by react
 * @param {*} date the date to be transformed
 * @returns
 */
const transformDateFormat = (date) => {
  const parsedDate = new Date(date);
  return `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;
};

export default ProspectField;
