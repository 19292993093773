import React from "react";
import { Button } from "react-bootstrap";

const ClearSelection = ({ handler, fieldData, tableOrSubformField }) => {
  return (
    <Button
      size={!tableOrSubformField ? "sm" : ""}
      className={`${tableOrSubformField ? "btn-xs " : ""}ms-3`}
      variant="outline-secondary"
      onClick={handler}
      disabled={!fieldData.entered}
    >
      Clear
    </Button>
  );
};

export default ClearSelection;
