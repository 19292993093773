import React from "react";

import { buildDisplayItems } from "../../../services/display_item_helper";

const TableCell = ({ displayItem }) => {
  const cellContent = buildDisplayItems(displayItem.children);
  return (
    <td>
      {/* Using flexbox to stack items vertically */}
      <div className="d-flex flex-column">{cellContent}</div>
    </td>
  );
};

export default TableCell;
