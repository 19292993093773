import { Dependencies } from "./dependency_checker";
import { useContext } from "react";
import { FormContext } from "../../contexts";

// Given a set of children, and a reference to the formData. Determines whether any child passes it's dependency
export const anyChildIsRendered = (children, formValues, fields, readonly) => {
  // Loop through the given children
  if (children !== undefined) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (child.type == "field") {
        // If the child is a field, get its fieldData and field
        const fieldData = formValues[child.fieldIdentifier];
        const field = fields.find((field) => {
          return field.identifier == child.fieldIdentifier;
        });

        if (fieldData === undefined) {
          return true;
        }
        const displayItemWidgetTypes = [
          "paragraph",
          "heading",
          "grey_alert",
          "orange_alert",
          "red_alert",
          "bulletpoint",
        ];

        // If this contains any "display item" type widgets and their dependencies are met, we consider a child to be rendered
        if (
          displayItemWidgetTypes.includes(child.widgetType) &&
          fieldData.dependency_met
        ) {
          return true;
        }

        if (fieldData.dependency_met) {
          // If the field is calculated, it is only shown on the view page
          if (readonly || (field && !field.calculated)) {
            // If its dependency has been met we can return true, as we only care that 1 or more children have been rendered
            return true;
          }
        }
      } else if (child.type == "subform") {
        // Subforms need to be rendered if there are no rows, so that new rows can be added
        const { subformsValues } = useContext(FormContext);

        const subform = subformsValues.find((subform) => {
          return subform.identifier === child.subformIdentifier;
        });

        // check the dependencies of the subform template data
        for (const key in subform.templateData.fields) {
          const field = subform.templateData.fields[key];

          if (field.dependency_met) {
            return true;
          }
        }
      }

      // Look recursively into table rows/radio table rows/subchildren
      if ("rows" in child) {
        if (child.type == "radioTable") {
          const anySubChildIsRendered = anyChildIsRendered(
            child.rows,
            formValues,
            fields,
            readonly
          );
          if (anySubChildIsRendered == true) {
            return true;
          }
        } else if (child.type == "table") {
          const anyRenderedSubChildren = child.rows.some((row) => {
            return (
              anyChildIsRendered(row, formValues, fields, readonly) == true
            );
          });
          if (anyRenderedSubChildren == true) {
            return true;
          }
        }
      }
      if ("children" in child) {
        const anySubChildIsRendered = anyChildIsRendered(
          child.children,
          formValues,
          fields,
          readonly
        );
        if (anySubChildIsRendered == true) {
          return true;
        }
      }
    }
    return false;
  }
};

const referenced_fields = (json) => {
  let fields = [];
  if (json["type"] == "localField") {
    fields.push(json["fieldIdentifier"]);
  } else if (json["type"] == "parentField") {
    fields.push(`parent.${json["fieldIdentifier"]}`);
  } else if (
    json["type"] == "systemVariable" &&
    json["variableType"] == "count"
  ) {
    fields.push(`${json["subformIdentifier"]}.count`);
  } else if (
    [
      "==",
      "!=",
      "<",
      "<=",
      ">",
      ">=",
      "IN",
      "CONTAINS",
      "EXISTS",
      "BLANK",
    ].includes(json["conditionType"])
  ) {
    const left = referenced_fields(json["left"]);
    const right = referenced_fields(json["right"]);
    fields = fields.concat(left);
    fields = fields.concat(right);
  } else if (["AND", "OR"].includes(json["conditionType"])) {
    const fields_for_conditions = json["conditions"].map((condition) => {
      return referenced_fields(condition);
    });
    fields_for_conditions.forEach((field_reference) => {
      fields = fields.concat(field_reference);
    });
  }
  return fields;
};

const buildContext = (
  stringJSON,
  processedFields,
  externalReferences,
  parentValues,
  subformsValues
) => {
  let fields = referenced_fields(stringJSON);

  let context = {};

  // Given a year and month, return the number of days
  const getNumberOfDays = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  context = Object.assign(context, externalReferences);

  fields.forEach((field) => {
    let processedValue = undefined;
    if (field.includes("parent.")) {
      // if the field contains "parent." make sure to search in the parent form for the value
      processedValue = parentValues[field.replace("parent.", "")].value;
    } else if (field.includes(".count")) {
      if (subformsValues == null) {
        return null;
      }
      const subformIdentifier = field.replace(".count", "");
      const subform = subformsValues.find((subform) => {
        return subform.identifier == subformIdentifier;
      });

      processedValue = subform.subformData.filter((subformEntry) => {
        return subformEntry.marked_for_delete != true;
      }).length;
    } else if (field.includes(".")) {
      // If the field identifer contains a "." it is foreign and will already be included in the context
      return null;
    } else if (processedFields[field].type == "partialDate") {
      // If the field is a partial date, its upper and lower bound is to be calculated
      let partialDate = processedFields[field].value;

      if (partialDate == null) {
        return null;
      }

      let dateParts, lowerBound, upperBound;
      if (partialDate.includes("/")) {
        dateParts = partialDate.split("/").reverse();
      } else {
        dateParts = partialDate.split("-");
      }
      if (dateParts.length == 3) {
        lowerBound = dateParts.join("-");
        upperBound = lowerBound;
      } else if (dateParts.length == 2) {
        lowerBound = `${dateParts[0]}-${dateParts[1]}-01`;
        upperBound = `${dateParts[0]}-${dateParts[1]}-${getNumberOfDays(
          dateParts[0],
          dateParts[1]
        )}`;
      } else {
        lowerBound = `${dateParts[0]}-01-01`;
        upperBound = `${dateParts[0]}-12-31`;
      }
      processedValue = [lowerBound, upperBound];
    } else {
      processedValue = processedFields[field].value;
    }

    // if the field is a subform field and is referencing parent field in dependency then if the dependency of parent field is not met, value should be considered to be null
    if (field.includes("parent.")) {
      const fieldData = parentValues[field.replace("parent.", "")];
      if (fieldData && fieldData.dependency_met == false) {
        context[field] = null;
      } else {
        context[field] = processedValue;
      }
    } else if (
      // For any other field, if the dependency is not met then the value should be considered to be null
      processedFields[field] &&
      processedFields[field].dependency_met == false
    ) {
      context[field] = null;
    } else {
      context[field] = processedValue;
    }
  });

  return context;
};

export const isDependencyMet = (
  dependency,
  formValues,
  externalReferences,
  parentValues,
  subformValues
) => {
  if (dependency.raw_dependency) {
    const stringJson = JSON.stringify(dependency.raw_dependency);
    const checker = new Dependencies.DependencyChecker(stringJson);

    const context = buildContext(
      dependency.raw_dependency,
      formValues,
      externalReferences,
      parentValues,
      subformValues
    );

    return checker.dependencyMet(JSON.stringify(context));
  } else {
    // Fields without a dependency should always be shown
    return true;
  }
};

const updateDependency = (
  fieldSource,
  dependency,
  externalReferences,
  parentValues,
  subformValues,
  ignoreDependencies
) => {
  const newField = {
    ...fieldSource[dependency.field_identifier],
    dependency_met: ignoreDependencies
      ? true
      : isDependencyMet(
          dependency,
          fieldSource,
          externalReferences,
          parentValues,
          subformValues
        ),
  };
  fieldSource[dependency.field_identifier] = newField;
};

export const updateAllDependencies = (
  dependencyData,
  formValues,
  subformValues,
  externalReferences,
  ignoreDependencies
) => {
  const newFormValues = { ...formValues };
  const newSubformValues = [...subformValues];

  dependencyData.forEach((dependency) => {
    if (dependency.subform_identifier) {
      const relevantSubform = newSubformValues.find(
        (subform) => subform.identifier == dependency.subform_identifier
      );

      // Process the dependency for each row of the subform
      relevantSubform.subformData.forEach((subformRow) => {
        updateDependency(
          subformRow.fields,
          dependency,
          externalReferences,
          newFormValues,
          newSubformValues,
          ignoreDependencies
        );
      });

      // Process the dependency for the template row of the subform
      updateDependency(
        relevantSubform.templateData.fields,
        dependency,
        externalReferences,
        newFormValues,
        newSubformValues,
        ignoreDependencies
      );
    } else {
      updateDependency(
        newFormValues,
        dependency,
        externalReferences,
        null,
        newSubformValues,
        ignoreDependencies
      );
    }
  });

  return [newFormValues, newSubformValues];
};
