import React, { useRef } from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import RevisionInfo from "../RevisionInfo";

const DatePickerInput = ({
  fieldData,
  onChangeHandler,
  errors,
  field,
  displayItem,
  readonly,
  labelSubtext,
  inputName,
  fieldID,
}) => {
  // Get our flatpickr ref
  const fp = useRef(null);

  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline = inputContext == "inline" || inputContext == "table";

  const fieldName = inputName;

  // Build our form label
  const formLabel = () => {
    if (displayItem.hideLabel) {
      return null;
    }

    let labelProps = {
      column: true,
      sm: displayInline ? "auto" : 5,
      className: "fw-bold lh-sm",
    };

    let upperLabel = <div>{displayItem.label}</div>;

    let lowerLabel = labelSubtext ? (
      <div className="fs-10pt fw-normal fst-italic text-muted">
        {labelSubtext}
      </div>
    ) : null;

    return (
      <Form.Label {...labelProps}>
        {upperLabel}
        {lowerLabel}
      </Form.Label>
    );
  };

  const toggleFlatpickr = (e) => {
    fp.current.flatpickr.open();
  };

  // Build our form input
  const formControl = () => {
    let inputGroupProps = {
      className: "small-field date-picker",
    };

    let flatpickrClasses = "form-control px-2 py-1";

    if (errors.length > 0) {
      inputGroupProps["className"] += " is-invalid";
      flatpickrClasses += " is-invalid";
    }

    if (!displayInline) {
      inputGroupProps["sm"] = 8;
    } else {
      inputGroupProps["sm"] = "auto";
    }

    const flatpickrOptions = {
      dateFormat: "d/m/Y",
      allowInput: true,
      clickOpens: false,
      disableMobile: true,
      parseDate,
      locale: {
        firstDayOfWeek: 1, // This sets the first day of the week to Monday
      },
    };

    return (
      <InputGroup {...inputGroupProps}>
        <Flatpickr
          ref={fp}
          id={fieldName}
          className={flatpickrClasses}
          options={flatpickrOptions}
          value={fieldData.value}
          name={fieldName}
          placeholder="dd/mm/yyyy"
          onChange={onChangeHandler}
        />
        <Button variant="primary" onClick={toggleFlatpickr}>
          <i className="far fa-calendar"></i>
        </Button>
      </InputGroup>
    );
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback className="error-message" type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  // A custom date parser, it will strictly enforce a dd/mm/yyyy format. Anything that does not match this will be cleared
  const parseDate = (dateString) => {
    const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/(\d{4})$/;

    if (!dateRegex.test(dateString)) {
      return null;
    }

    const dateParts = dateString.split("/");

    return new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
  };

  if (displayInline) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className={`${field.hint ? "" : "mb-3"}`}
          id={fieldID}
        >
          {formLabel()}
          <Col sm={"auto"} className="ps-0">
            {formControl()}
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {errorFeedback}
          </Col>
        </Form.Group>
      </Col>
    );
  } else {
    return (
      <Form.Group
        controlId={fieldName}
        as={Row}
        className={`${field.hint ? "" : "mb-3"}`}
        id={fieldID}
      >
        {formLabel()}
        <Col>
          {formControl()}
          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
        </Col>
      </Form.Group>
    );
  }
};

export default DatePickerInput;
