import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const TextField = ({
  fieldName,
  label,
  value,
  errors,
  onFieldChange,
  inline = false,
  disabled = false,
  inputClassName,
  labelClassName,
}) => {
  const FormControl = (
    <Form.Control
      type="text"
      value={value}
      onChange={(e) => {
        onFieldChange(fieldName, e.target.value);
      }}
      isInvalid={errors.length > 0}
      disabled={disabled}
      className={inputClassName}
    />
  );

  const ErrorFeedback = (
    <Form.Control.Feedback className="error-message" type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (inline) {
    return (
      <Form.Group as={Row} className="mb-3" controlId={fieldName}>
        <Form.Label column sm={4} className={labelClassName}>
          {label}
        </Form.Label>
        <Col sm={8}>
          {FormControl}
          {ErrorFeedback}
        </Col>
      </Form.Group>
    );
  } else {
    return (
      <Form.Group className="mb-3" controlId={fieldName}>
        <Form.Label className={labelClassName}>{label}</Form.Label>
        {FormControl}
        {ErrorFeedback}
      </Form.Group>
    );
  }
};

export default TextField;
